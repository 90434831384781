import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import axios from 'axios';

export default function Layout(props) {

  let user = JSON.parse(sessionStorage.getItem(sessionStorage.key(0)));
  if (user) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
  }

  return (
    <div>
      <NavMenu/>
      <Container>
        {props.children}
      </Container>
    </div>
  );
}
