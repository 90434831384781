import React from 'react';

export default function Home() {
  // static displayName = Home.name;

  return (
    <div>
      <h1>Hello, world!</h1>
    </div>
  );
}
