import React, { useEffect, useState } from 'react';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { FormDatePicker, FormInput, FormMultiSelect, FormTextArea } from '../shared/FormComponents';
import { requiredValidator } from '../shared/FormValidators';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import withValueField from '../shared/WithValueField';
import { jobTypes } from '../shared/constants';
import axios, { AxiosResponse } from 'axios';

const DropDownListWithValueField = withValueField(DropDownList);
const today = new Date();

export default function JobCreateForm({afterSave, errorOnSave, onClose}) {

  const [jobCategories, setJobCategories] = useState([]);

  const handleSubmit = (dataItem) => {
    axios.post('api/recurring-jobs', dataItem)
      .then(({status, data}) => {
        if (status === 200) {
          afterSave(data);
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(() => {
        onClose();
        errorOnSave();
      });
  };

  useEffect(() => {
    fetchJobCategories();
  }, []);

  const fetchJobCategories = () => {
    axios.get(`api/job-categories`, {method: 'GET', accept: 'application/json'})
      .then(({data}: AxiosResponse) => {
        setJobCategories(data)
      });
  };

  return (<Form
    onSubmit={handleSubmit}
    render={(formRenderProps) => (
      <Form
        initialValues={{
          name: '',
          startDate: new Date()
        }}
        onSubmit={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <Field
              id={'name'}
              name={'name'}
              label={'Name'}
              component={FormInput}
              validator={requiredValidator}
              autoComplete='false'
            />
            <Field
              id={'description'}
              name={'description'}
              label={'Description'}
              component={FormTextArea}
            />
            <Field
              id={'jobType'}
              name={'jobType'}
              label={'Job Type'}
              textField="label"
              dataItemKey="value"
              valueField="value"
              data={jobTypes}
              component={DropDownListWithValueField}
              validator={requiredValidator}
            />
            <Field
              id={'tags'}
              name={'tags'}
              label={'Tags'}
              allowCustom={true}
              component={FormMultiSelect}
            />
            <Field
              id={'jobCategoryId'}
              name={'jobCategoryId'}
              label={'Job Category'}
              data={jobCategories}
              textField="name"
              dataItemKey="id"
              valueField="id"
              component={DropDownListWithValueField}
              validator={requiredValidator}
            />
            <Field
              id={'startDate'}
              name={'startDate'}
              label={'Start Date'}
              min={today}
              component={FormDatePicker}
              validator={requiredValidator}
            />
            <Field
              id={'endDate'}
              name={'endDate'}
              label={'End Date'}
              min={today}
              component={FormDatePicker}
              optional={true}
            />
            <Field
              id={'notes'}
              name={'notes'}
              label={'Notes'}
              component={FormTextArea}
            />
            <div className="k-form-buttons float-end">
              <hr/>
              <button
                type={'button'}
                className="k-button" onClick={onClose}>
                Cancel
              </button>
              <button
                type={'submit'}
                className="k-button k-button-primary"
                disabled={!formRenderProps.allowSubmit}>
                Submit
              </button>
            </div>
          </FormElement>
        )}
      />
    )}
  />);
}
