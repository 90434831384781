import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../shared/LoadingIndicator';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import axios from 'axios';
import { useAuth } from 'oidc-react';

const initialDataState = {
  skip: 0,
  take: 20,
};

export default function JobExecutionHistory() {
  const auth = useAuth();
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(initialDataState);

  let { id } = useParams();

  useEffect(() => {
    if(auth && auth.userData) {
      fetchData(id);
    }
  }, [auth]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  function fetchData(id) {
    setIsLoading(true);
    // const init = {method: 'GET', accept: 'application/json', headers: {}};
    axios.get(`api/recurring-jobs/${id}/history`)
      .then(({ data }) => {
        setHistory(data?.map(item => {
          return {
            id: item.id,
            dateTimeStarted: new Date(`${item.dateTimeStarted}Z`),
            dateTimeEnded: item.dateTimeEnded ? new Date(`${item.dateTimeEnded}Z`) : '',
            remarks: item.remarks,
            executionStatus: item.executionStatus
          };
        }));
        setIsLoading(false);
      });
  }

  return (<div className="mb-5">
    <LoadingIndicator isLoading={isLoading} />
    {!isLoading &&
      <Grid
        style={{
          height: 'auto',
        }}
        data={history.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={history.length}
        onPageChange={pageChange}
        pageable={{pageSizes: true}}
      >
        <GridColumn field="id" title="ID" width="280px" />
        <GridColumn field="dateTimeStarted" title="Started" width="220px" format="{0:MMM-dd-yyyy hh:mm:ss a}" />
        <GridColumn field="dateTimeEnded" title="Ended" width="220px" format="{0:MMM-dd-yyyy hh:mm:ss a}" />
        <GridColumn field="remarks" title="Remarks" />
        <GridColumn field="executionStatus" title="Execution Status" width="150px" />
      </Grid>
    }
  </div>);
}
