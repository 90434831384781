import * as React from 'react';
import {FaInfoCircle} from 'react-icons/fa';
import './InfoNotication.css';

export default function InfoNotification(props){
    return (
        <div className="info-notification" role="alert">
        <FaInfoCircle className="icon"/> {props.message}
    </div>
    );
}
