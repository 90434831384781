import * as React from 'react'
import { RandomNumber } from './helpers/RandomNumber'
import './CustomScheduler.css'
import CustomSchedulerDaily from './customs-scheduler-daily/CustomSchedulerDaily'
import CustomSchedulerMonthly from './custom-scheduler-monthly/CustomSchedulerMonthly'

export default function CustomScheduler () {
  return (
    <div className="offset-3 col-5 custom-scheduler" key={'CustomScheduler' + RandomNumber ()}>
      <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="daily-tab" data-bs-toggle="tab" data-bs-target="#daily" type="button"
                  role="tab" aria-controls="daily" aria-selected="true">Daily
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="weekly-tab" data-bs-toggle="tab" data-bs-target="#weekly" type="button"
                  role="tab" aria-controls="weekly" aria-selected="false">Weekly
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="monthly-tab" data-bs-toggle="tab" data-bs-target="#monthly" type="button"
                  role="tab" aria-controls="monthly" aria-selected="false">Monthly
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily-tab">
          <CustomSchedulerDaily/>
        </div>
        <div className="tab-pane fade" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
          <CustomSchedulerMonthly/>
        </div>
      </div>
    </div>
  )
}
