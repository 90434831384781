import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button, Toolbar, ToolbarItem, ToolbarSpacer, } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import JobCreateForm from './JobCreateForm';
import LoadingIndicator from '../shared/LoadingIndicator';
import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';
import { Notification, NotificationGroup, } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import axios from 'axios';

export default function JobList() {
  const [isLoading, setIsLoading] = useState(false);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [recentJobId, setRecentJobId] = useState([]);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20
  });

  const notificationPosition = {
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  };

  const [notificationState, setNotificationState] = useState({
    success: false,
    error: false
  });

  const onToggleNotification = (flag) => setNotificationState({
    ...notificationState,
    [flag]: !notificationState[flag]
  });

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  function afterSave(id) {
    if (id) {
      setRecentJobId(id);
      onToggleNotification('success');
    }
    toggleDialog();
  }

  function reloadData() {
    fetchData(dataState);
  }

  useEffect(() => {
    setTimeout(() => fetchData(dataState), 100);
  }, []);

  function handleDataStateChange(changeEvent) {
    setDataState(changeEvent.dataState);
    fetchData(changeEvent.dataState);
  }

  function fetchData(dataState) {
    setIsLoading(true);
    const queryStr = `${toDataSourceRequestString(dataState)}`;
    const hasGroups = dataState.group && dataState.group.length;

    const base_url = 'api/recurring-jobs';

    axios.get(`${base_url}?${queryStr}`, {
      method: 'GET',
      accept: 'application/json'
    }).then(({data, total}) => {
      setData(hasGroups ? translateDataSourceResultGroups(data) : data);
      setTotal(total);
      setDataState(dataState);
      setIsLoading(false);
    });
  }

  const CustomLinkCell = (props) => {
    return (
      <td>
        <Link to={`/jobs/${props.dataItem.id}/details`} className="btn-link">
          {props.dataItem.name}
        </Link>
      </td>
    );
    };

    const rowRender = (trElement, props) => {
        const testRow = props.dataItem.tagString.toLowerCase().includes('test');
        const yellow = {
            backgroundColor: "rgb(255, 255, 0, 0.32)",
        };
        const trProps = testRow ? {
            style: yellow
        } : {};
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    }

  const CronCell = (props) => {
    return (
      <td>
        {props.dataItem.useCron && <span className="fw-bold k-color-success">Yes</span>}
        {!props.dataItem.useCron && <span className="fw-bold k-color-error">No</span>}
      </td>
    );
  };

  const ActiveCell = (props) => {
    return (
      <td>
        {props.dataItem.isEnabled && <span className="fw-bold k-color-success">Yes</span>}
        {!props.dataItem.isEnabled && <span className="fw-bold k-color-error">No</span>}
      </td>
    );
  };

  return (
    <div>
      <div className="row mb-2">
        <div className="col-12">
          <Toolbar>
            <ToolbarItem>
              <Button icon="add" title="Add" onClick={toggleDialog}>
                Add
              </Button>
            </ToolbarItem>
            <ToolbarSpacer/>
            <ToolbarItem>
              <Button icon="refresh" title="Refresh" onClick={reloadData}>
              </Button>
            </ToolbarItem>
          </Toolbar>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <Grid
            style={{height: '100%'}}
            filterable={true}
            sortable={true}
            pageable={{pageSizes: true}}
            total={total}
            data={data}
            skip={dataState?.skip}
            pageSize={dataState?.take}
            filter={dataState?.filter}
            sort={dataState?.sort}
            rowRender={rowRender}
            onDataStateChange={handleDataStateChange}>
            <GridColumn field="id" title="ID" width="100px"/>
            <GridColumn field="name" title="Name" width="300px" cell={CustomLinkCell}/>
            <GridColumn field="jobCategory.name" title="Job Category" width="200px"/>
            <GridColumn field="tagString" title="Tags" width="200px"/>
            <GridColumn field="description" title="Description"/>
            <GridColumn field="useCron" title="Use Cron" filter="boolean" cell={CronCell} width="100"/>
            <GridColumn field="isEnabled" title="Enabled" filter="boolean" cell={ActiveCell} width="100"/>
          </Grid>
          <LoadingIndicator isLoading={isLoading}/>
        </div>
      </div>
      {visibleDialog && (
        <Dialog title={'Add recurring job'}
                width={700}
                onClose={toggleDialog}>
          <JobCreateForm afterSave={afterSave}
                         errorOnSave={() => onToggleNotification('error')}
                         onClose={toggleDialog}/>
        </Dialog>
      )}
      <NotificationGroup style={notificationPosition}>
        <Fade>
          {notificationState.success && (
            <Notification
              type={{
                style: 'success',
                icon: true,
              }}
              closable={true}
              onClose={() => setNotificationState({...notificationState, success: false})}
            >
              <span>Success! <Link
                to={`/jobs/${recentJobId}/details`}>Click here</Link> to view recently added job</span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {notificationState.error && (
            <Notification
              type={{
                style: 'error',
                icon: true,
              }}
              closable={true}
              onClose={() => setNotificationState({...notificationState, error: false})}
            >
              <span>Oops! Something went wrong ...</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </div>
  );
}
