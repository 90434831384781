import * as React from "react";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Field } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { FormSwitch } from "../shared/FormComponents";
import { JobFieldsGridEditContext } from "./JobFieldsFormGrid";

const FORM_DATA_INDEX = "formDataIndex";

const requiredValidator = (value) => ((value || value === 0) ? "" : "The field is required");

export const DisplayValue = (fieldRenderProps) => {
  return <>{fieldRenderProps.value}</>;
};

const FormSwitch_ = (fieldRenderProps) => {
  const {validationMessage, visited, ...others} = fieldRenderProps;
  return (
    <div>
      <FormSwitch offLabel={'no'} onLabel={'yes'} {...others} ></FormSwitch>
    </div>
  );
};

const TextInputWithValidation = (fieldRenderProps) => {
  const {validationMessage, visited, ...others} = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const NumericTextBoxWithValidation = (fieldRenderProps) => {
  const {validationMessage, visited, ...others} = fieldRenderProps;
  const anchor = React.useRef(null);
  return (
    <div>
      <NumericTextBox {...others} ref={anchor}/>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export const NameCell = (props) => {
  const {parentField, editIndex} = React.useContext(JobFieldsGridEditContext);
  const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
  return (
    <td>
      <Field
        component={isInEdit ? TextInputWithValidation : DisplayValue}
        name={`${parentField}[${props.dataItem[FORM_DATA_INDEX]}].${props.field}`}
        validator={requiredValidator}
      />
    </td>
  );
};

export const DescriptionCell = (props) => {
    const { parentField, editIndex } = React.useContext(JobFieldsGridEditContext);
    const isInEdit = props.dataItem[FORM_DATA_INDEX] === editIndex;
    return (
        <td>
            <Field
                component={isInEdit ? TextInputWithValidation : DisplayValue}
                name={`${parentField}[${props.dataItem[FORM_DATA_INDEX]}].${props.field}`}
            />
        </td>
    );
};

export const ValueCell = (props) => {
  const {parentField, editIndex} = React.useContext(JobFieldsGridEditContext);
  let component = null;
  const type = props.dataItem.type;
  if (type === 'number') {
    component = NumericTextBoxWithValidation;
  }

  return (
    <td>
      {type === 'bool' && <Field
        checked={props.dataItem.value}
        component={FormSwitch_}
        name={`${parentField}[${props.dataItem[FORM_DATA_INDEX]}].${props.field}`}
      />}
      {type !== 'bool' && <Field
        component={component || TextInputWithValidation}
        name={`${parentField}[${props.dataItem[FORM_DATA_INDEX]}].${props.field}`}
        validator={requiredValidator}
      />
      }
    </td>
  );
};

