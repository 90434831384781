// noinspection DuplicatedCode

import React, { useEffect, useState } from 'react';
import { RandomNumber } from '../helpers/RandomNumber';
import Heading from '../shared/Heading/Heading';
import InfoNotification from '../shared/InfoNotification/InfoNotification';
import './CustomSchedulerMonthly.css';
import { RadioButton } from '@progress/kendo-react-inputs';
import CustomTimeSelector from '../shared/CustomTimeSelector/CustomTimeSelector';
import { FaExclamationTriangle } from 'react-icons/all';
import axios, { AxiosResponse } from "axios";

export default function CustomSchedulerMonthly(props) {
  const [schedule, setSchedule] = useState({
    hours: [],
    minutes: [],
    occurrence: '',
    value: ''
  });
  const [occurrence, setOccurrence] = useState('SpecificDayOfTheMonth');
  const [selectedHours, setSelectedHours] = useState([]);
  const [selectedMinutes, setSelectedMinutes] = useState([]);
  const [cronDescription, setCronDescription] = useState('');
  const [reRender, setReRender] = useState(false);
  const [hasTouched, setHasTouched] = useState(false);
  const allMonthDays = Array.from({ length: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 0).getDate() }, (_, i) => i + 1);
  const toOrdinal = (n) => {
    return n + (['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th');
  };
  const onValueChange = React.useCallback(() => {
    props.onValueChange({ value: schedule });
    props.onChange({ value: schedule });
    fetchMonthlyCronDescription();
    setTimeout(() => setHasTouched(true), 500);
  }, [props, schedule]);

  const MonthOnWhichDays = () => {
    return (
      <div className="on-which-days">
        <div className="d-flex align-items-center align-content-center  mb-2">
          <div className="d-block mb-1">
            <RadioButton checked={occurrence === 'SpecificDayOfTheMonth'}
                         value="specific-in-month"
                         key={'Specific-in-month' + RandomNumber()}
                         id="specific-in-month"
                         name="occurrence"
                         onClick={(e) => {
                           schedule.occurrence = 'SpecificDayOfTheMonth';
                           schedule.value = '';
                           setSchedule(schedule);
                           setOccurrence('SpecificDayOfTheMonth');
                           onValueChange();
                         }} />
          </div>
          <label className="d-block ms-1 me-2" htmlFor="specific-in-month">Specific day of the month</label>
          <div className="d-inline-block me-2">
            <select className="form-select form-select-sm"
                    value={schedule.value}
                    disabled={occurrence !== 'SpecificDayOfTheMonth'}
                    onChange={(e) => {
                      schedule.value = e.target.value;
                      setSchedule(schedule);
                      onValueChange();
                    }}>
              {allMonthDays.map((d, i) => {
                return (
                  <option key={'Day-of-week-' + i} value={d}>{toOrdinal(d)}</option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const fetchMonthlyCronDescription = () => {
    axios.post('api/crons/monthly', schedule)
      .then(({data, status, headers}: AxiosResponse) => {
        if (status === 200) {
          setCronDescription(data);
        }
      });
  };

  const onHourAdd = (h) => {
    if (!selectedHours.find((v) => v === h)) {
      selectedHours.push(h);
      setSelectedHours(selectedHours);
      schedule.hours = selectedHours;
      setSchedule(schedule);
      onValueChange();
    }
  };
  const onMinutesAdd = (m) => {
    if (!selectedMinutes.find((v) => v === m)) {
      selectedMinutes.push(m);
      setSelectedMinutes(selectedMinutes);
      schedule.minutes = selectedMinutes;
      setSchedule(schedule);
      onValueChange();
    }
  };
  const onHourRemove = (h) => {
    let tmp = selectedHours.filter((v) => v !== h);
    setSelectedHours(tmp);
    schedule.hours = tmp;
    setSchedule(schedule);
    onValueChange();
  };
  const onMinutesRemove = (m) => {
    let tmp = selectedMinutes.filter((v) => v !== m);
    setSelectedMinutes(tmp);
    schedule.minutes = tmp;
    setSchedule(schedule);
    onValueChange();
  };
  useEffect(() => {
    if (props.value) {
      schedule.occurrence = !props.value.occurrence ? 'FirstDayOfTheMonth' : props.value.occurrence;
      schedule.value = !props.value.value ? '' : props.value.value;
      schedule.hours = !props.value.hours ? [] : props.value.hours;
      schedule.minutes = !props.value.minutes ? [] : props.value.minutes;
      setSelectedHours(schedule.hours);
      setSelectedMinutes(schedule.minutes);
      setSchedule(schedule);
      setOccurrence(schedule.occurrence);
      setReRender(true);
      fetchMonthlyCronDescription();
    }
  }, [props.value]);
  useEffect(() => {
    if (reRender) {
      setReRender(false);
    }
  }, [reRender]);

  return (
    <div className="mt-2 custom-scheduler-monthly" key={'custom-scheduler-daily-' + RandomNumber()}>
      <div className="d-block mb-3">
        <Heading title="On which days?" />
        <MonthOnWhichDays />
      </div>
      <div className="d-block mb-3">
        <Heading title="At what times?" />
        <InfoNotification message="All times are in Pacific(PST) Time Zone" />
        {hasTouched && (props.errors.hours || props.errors.minutes) && (
          <div className="d-flex alert alert-danger align-content-center align-items-center p-2 rounded-0 px-3"><span
            className="me-1"><FaExclamationTriangle /></span> {props.validationMessage}</div>
        )}
        <CustomTimeSelector currentHours={selectedHours}
                            currentMinutes={selectedMinutes}
                            onHourAdd={onHourAdd}
                            onHourRemove={onHourRemove}
                            onMinutesRemove={onMinutesRemove}
                            onMinutesAdd={onMinutesAdd} />
      </div>
      <div className="text-muted">
        {cronDescription}
      </div>
      <hr />
    </div>
  );
}
