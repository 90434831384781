import React, { Component } from 'react';

export class LoginCallback extends Component {
  static displayName = LoginCallback.name;

  render() {
    return (
      <div className="mt-5">
        <h3><span className="text-success">&#10004;</span> Login successful.</h3>
      </div>
    );
  }
}
