import { FaCaretRight, FaPlus, FaTrashAlt } from 'react-icons/fa';
import React, { useState } from 'react';
import './CustomTimeSelector.css';

export default function CustomTimeSelector(props) {
  const { currentHours, currentMinutes, onHourAdd, onMinutesAdd, onHourRemove, onMinutesRemove } = props;
  const hours = Array.from({ length: 24 }, (_, i) => i + 1);
  const minutes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
  const [selectedHour, setSelectedHour] = useState('1');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const onAddHour = () => {
    if (selectedHour.length > 0) {
      onHourAdd(selectedHour);
      setSelectedHour('');
    }
  };
  const onAddMinutes = () => {
    if (selectedMinute.length > 0) {
      onMinutesAdd(selectedMinute);
      setSelectedMinute('');
    }
  };

  const removeHour = (e, h) => {
    e.preventDefault();
    setSelectedHour(h);
    onHourRemove(h);
    setSelectedHour('');
  };

  const removeMinutes = (e, m) => {
    e.preventDefault();
    setSelectedMinute(m);
    onMinutesRemove(m);
    setSelectedMinute('');
  };

  const pad = (pad, str) => {
    return (pad + str).slice(-pad.length);
  };
  return (<div className="time-selector-component">
    <div className="row mb-2">
      <div className="col-6">
        <label>On hours...</label>
      </div>
      <div className="col-6">
        <label>... and minutes</label>
      </div>
    </div>
    {
      currentHours && currentMinutes && (
        <div className="row">
          <div className="col-6">
            <ul className="list-unstyled">
              {
                currentHours?.map((h, i) => (
                  <li key={`h-item-${i.toString()}`} className="d-flex align-items-center align-content-center">
                    <FaCaretRight />
                    <span>{pad('00', h)}:00</span>
                    <a href="#"
                       onClick={(e) => removeHour(e, h)}
                       className="btn btn-link btn-sm text-decoration-none"><FaTrashAlt /> Remove</a>
                  </li>))
              }
            </ul>
          </div>
          <div className="col-6">
            <ul className="list-unstyled">
              {
                currentMinutes?.map((m, i) => (
                  <li key={`h-item-${i.toString()}`} className="d-flex align-items-center align-content-center">
                    <FaCaretRight />
                    <span>:{pad('00', m)}</span>
                    <a href="#"
                       onClick={(e) => removeMinutes(e, m)}
                       className="btn btn-link btn-sm text-decoration-none"><FaTrashAlt /> Remove</a>
                  </li>))
              }
            </ul>
          </div>
        </div>
      )
    }
    <div className="row">
      <div className="col-3">
        <div className="d-flex">
          <select className="form-select form-select-sm" onChange={(e) => setSelectedHour(e.target.value)}>
            {
              hours.map((h, i) => (
                <option value={h} key={`item-${i.toString()}`}>{pad('00', h)}:00</option>))
            }
          </select>
          <button type="button" className="k-button btn-sm btn-add-time" onClick={onAddHour}>
            <FaPlus className="icon" />
            Add Hour
          </button>
        </div>
      </div>
      <div className="col-3 offset-3">
        <div className="d-flex">
          <select className="form-select form-select-sm" onChange={(e) => setSelectedMinute(e.target.value)}>
            {
              minutes.map((m, i) => (<option value={pad('00', m)}
                                             key={`min-item-${i.toString()}`}>{':' + pad('00', m)}</option>))
            }
          </select>
          <button type="button" className="k-button btn-sm btn-add-time" onClick={onAddMinutes}>
            <FaPlus className="icon" />
            Add Mins
          </button>
        </div>
      </div>
    </div>
  </div>);
}
