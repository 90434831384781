import React, { Component } from 'react';
import Home from './components/Home';
import '@progress/kendo-theme-default/dist/all.css';
// noinspection NpmUsedModulesInstalled
import { Route, Switch, } from 'react-router-dom';
import './custom.css';
import JobList from './components/jobs/JobList';
import JobDetail from './components/jobs/JobDetail';
import NotFound from './components/NotFound';
import CustomScheduler from './components/custom-scheduler/CustomScheduler';
import { AuthProvider } from 'oidc-react';
import { AuthProviderProps } from 'oidc-react/build/src/AuthContextInterface';
import { LoginCallback } from './components/authentication/LoginCallback';
import Layout from './components/Layout';

export default class App extends Component {
  static displayName = App.name;

  render() {
    const { authority, clientId, redirectUri, post_logout_redirect_uri } = window.config

    const oidcConfig: AuthProviderProps = {
      onSignIn: () => {
        window.location.href = '/';
      },
      authority: authority,
      clientId: clientId,
      redirectUri: redirectUri,
      post_logout_redirect_uri: post_logout_redirect_uri,
      responseType: 'code',
      scope: 'openid profile CnsSchedulerApi CNS.Bifrost.WebAPI openid profile'
    };
    return (
      <AuthProvider {...oidcConfig}>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/jobs" component={JobList}/>
            <Route exact path="/custom-scheduler" component={CustomScheduler}/>
            <Route exact path="/jobs/:id/details" children={<JobDetail/>}/>
            <Route exact path="/jobs/:id/execution-history" children={<JobDetail/>}/>
            <Route exact path="/authentication/login-callback" children={<LoginCallback/>}/>
            <Route path="*" component={NotFound}/>
          </Switch>
        </Layout>
      </AuthProvider>
    );
  }
};
