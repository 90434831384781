export const jobTypes = [
  {
    label: 'HTTP Parameters',
    value: 'HttpParameter',
  },
  {
    label: 'Fields',
    value: 'Fields',
  },
];

export const scheduleTabs = [
  {
    label: 'Daily',
    value: 'Daily',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
];

export const httpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];
