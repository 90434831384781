import React from 'react'

export default function LoadingIndicator({isLoading}) {
  return !isLoading ? null : (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  )
}