import * as React from 'react';
import { Error } from '@progress/kendo-react-labels';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { NameCell, ValueCell, DescriptionCell } from "./JobFieldsGridEditors";

export const JobFieldsGridEditContext = React.createContext({});
const FORM_DATA_INDEX = "formDataIndex";

export const JobFieldsFormGrid = (fieldArrayRenderProps) => {
  const {validationMessage, visited, name, dataItemKey} =
    fieldArrayRenderProps;

  const dataWithIndexes = fieldArrayRenderProps.value?.map((item, index) => {
    return {...item, [FORM_DATA_INDEX]: index};
  });
  return (
    <JobFieldsGridEditContext.Provider
      value={{
        parentField: name,
      }}
    >
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      <Grid data={dataWithIndexes} dataItemKey={dataItemKey}>
        <GridColumn field="name" title="Name" cell={NameCell} width="330px"/>
        <GridColumn field="description" title="Description" cell={DescriptionCell}/>
        <GridColumn field="value" title="Value" cell={ValueCell} width="350px"/>
      </Grid>
    </JobFieldsGridEditContext.Provider>
  );
};
