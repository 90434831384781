import * as React from 'react';
import './Heading.css';
import {RandomNumber} from '../../helpers/RandomNumber';

export default function Heading(props) {
    return (
        <div className="heading" key={'Heading' + RandomNumber()}>
            <strong>{props.title}</strong>
            <span className="line"></span>
        </div>
    );
}
