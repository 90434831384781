import React, { useEffect, useState } from 'react';
import Heading from '../shared/Heading/Heading';
import { RandomNumber } from '../helpers/RandomNumber';
import './CustomSchedulerDaily.css';
import OrText from '../shared/OrText/OrText';
import InfoNotification from '../shared/InfoNotification/InfoNotification';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import CustomTimeSelector from '../shared/CustomTimeSelector/CustomTimeSelector';
import { FaExclamationTriangle } from 'react-icons/all';
import axios, { AxiosResponse } from "axios";

export default function CustomSchedulerDaily(props) {
  console.log(props);
  const [schedule, setSchedule] = useState({ hours: [], minutes: [], weekdays: false, weekends: false });
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const [selectedHours, setSelectedHours] = useState([]);
  const [selectedMinutes, setSelectedMinutes] = useState([]);
  const [cronDescription, setCronDescription] = useState('');
  const [reRender, setReRender] = useState(false);
  const [hasTouched, setHasTouched] = useState(false);
  const onValueChange = React.useCallback(() => {
    props.onValueChange({ value: schedule });
    props.onChange({ value: schedule });
    fetchDailyCronDescription();
    setTimeout(() => setHasTouched(true), 500);
  }, [props.onChange, props.value]);
  const isExcludedDay = (v) => {
    return Array.isArray(schedule.excludes) && !!schedule.excludes.find(d => d === v);
  };
  const excludeDay = (e, v) => {
    const b: HTMLButtonElement = e.nativeEvent.target;
    if (Array.isArray(schedule.excludes)) {
      if (!isExcludedDay(v)) {
        schedule.excludes.push(v);
        b.classList.add('k-state-selected');
      } else {
        schedule.excludes = schedule.excludes.filter(d => d !== v);
        b.classList.remove('k-state-selected');
      }
    }
    setSchedule(schedule);
    onValueChange();
  };
  const DaySelector = () => {
    return (<div className="day-selector" key={'Days' + RandomNumber()}>
      <ButtonGroup width="200" key={'CustomSchedulerDays-ButtonGroup' + RandomNumber()}>
        {daysOfWeek.map(day => {
          return (
            <Button selected={isExcludedDay(day)} type="button"
                    key={'CustomSchedulerDays-ButtonGroup-Button' + RandomNumber()}
                    style={{ marginRight: '10px' }} value={day}
                    onClick={(e) => excludeDay(e, day)}
                    togglable={true}>{day}</Button>
          );
        })}
      </ButtonGroup>
    </div>);
  };

  const fetchDailyCronDescription = () => {
    axios.post('api/crons/daily', schedule)
      .then(({data, status, headers}: AxiosResponse) => {
        if (status === 200) {
          setCronDescription(data);
        }
      });
  };

  const onHourAdd = (h) => {
    if (!selectedHours.find((v) => v === h)) {
      selectedHours.push(h);
      setSelectedHours(selectedHours);
      schedule.hours = selectedHours;
      setSchedule(schedule);
      onValueChange();
    }
  };
  const onMinutesAdd = (m) => {
    if (!selectedMinutes.find((v) => v === m)) {
      selectedMinutes.push(m);
      setSelectedMinutes(selectedMinutes);
      schedule.minutes = selectedMinutes;
      setSchedule(schedule);
      onValueChange();
    }
  };
  const onHourRemove = (h) => {
    let tmp = selectedHours.filter((v) => v !== h);
    setSelectedHours(tmp);
    schedule.hours = tmp;
    setSchedule(schedule);
    onValueChange();
  };
  const onMinutesRemove = (m) => {
    let tmp = selectedMinutes.filter((v) => v !== m);
    setSelectedMinutes(tmp);
    schedule.minutes = tmp;
    setSchedule(schedule);
    onValueChange();
  };

  useEffect(() => {
    if (props.value) {
      schedule.excludes = !props.value.excludes ? [] : props.value.excludes;
      schedule.weekdays = !props.value.weekdays ? false : props.value.weekdays;
      schedule.weekends = !props.value.weekends ? false : props.value.weekends;
      schedule.hours = !props.value.hours ? [] : props.value.hours;
      schedule.minutes = !props.value.minutes ? [] : props.value.minutes;
      setSelectedHours(schedule.hours);
      setSelectedMinutes(schedule.minutes);
      setSchedule(schedule);
      fetchDailyCronDescription();
    }
  }, [props.value, props.value.excludes, props.value.schedules, props.value.weekdays, props.value.weekends]);
  useEffect(() => {
    if (reRender) {
      setReRender(false);
    }
  }, [reRender]);

  return (
    <div className="mt-2 custom-scheduler-daily" key={'custom-scheduler-daily-' + RandomNumber()}>
      <div className="d-block mb-3">
        <Heading title="Exclude days?" />
        <InfoNotification message="If no options are selected, the schedule will run every day." />
        <div className="row mb-2">
          <div className="col-3">
            <Checkbox checked={schedule.weekdays} label="Weekdays" value="1" key={'Weekdays' + RandomNumber()}
                      onClick={() => {
                        schedule.weekdays = !schedule.weekdays;
                        setSchedule(schedule);
                        setReRender(true);
                        onValueChange();
                      }} />
          </div>
          <div className="col-6">
            <Checkbox checked={schedule.weekends} label="Weekends" value="1" key={'Weekends' + RandomNumber()}
                      onClick={() => {
                        schedule.weekends = !schedule.weekends;
                        setSchedule(schedule);
                        setReRender(true);
                        onValueChange();
                      }} />
          </div>
        </div>
        <OrText message="select specific days to exclude" />
        <DaySelector />
      </div>
      <div className="d-block mb-3">
        <Heading title="At what times?" />
        <InfoNotification message="All times are in Pacific (PST) Time Zone" />
        {hasTouched && (props.errors.hours || props.errors.minutes) && (
          <div className="d-flex alert alert-danger align-content-center align-items-center p-2 rounded-0 px-3"><span
            className="me-1"><FaExclamationTriangle /></span> {props.validationMessage}</div>
        )}
        <CustomTimeSelector currentHours={selectedHours}
                            currentMinutes={selectedMinutes}
                            onHourAdd={onHourAdd}
                            onHourRemove={onHourRemove}
                            onMinutesRemove={onMinutesRemove}
                            onMinutesAdd={onMinutesAdd} />
      </div>
      <div className="text-muted">
        {cronDescription}
      </div>
      <hr />
    </div>
  );
}
